<template>
  <el-main>
    <el-form label-width="200px" class="addForm">
      <el-form-item label="名称：">
        <el-input v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="昵称：">
        <el-input v-model="addForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="性别：">
        <el-radio-group v-model="addForm.sex">
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="硬件设备：">
        <el-select
          v-model="addForm.shipper_id"
          filterable
          placeholder="请选择设备"
          style="width: 100%"
        >
          <el-option
            v-for="item in shipperList"
            :key="item.id"
            :label="item.equipment_no"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="省市区：" class="city">
        <div class="box">
          <el-select
            v-model="addForm.province"
            placeholder="请选择"
            @change="(val) => sonArea(0, val)"
          >
            <el-option
              v-for="(item, index) in area"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.city"
            placeholder="请选择"
            @change="(val) => sonArea(1, val)"
          >
            <el-option
              v-for="(item, index) in cityArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.county"
            placeholder="请选择"
            @change="(val) => sonArea(2, val)"
          >
            <el-option
              v-for="(item, index) in areaArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="现住址：">
        <el-input v-model="addForm.address"></el-input>
      </el-form-item>
      <el-form-item label="年龄：">
        <el-input v-model="addForm.age"></el-input>
      </el-form-item>
      <el-form-item label="电话：">
        <el-input v-model="addForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="生日：">
        <el-input v-model="addForm.birthday"></el-input>
      </el-form-item>
      <el-form-item label="房间号：">
        <el-input v-model="addForm.room_num"></el-input>
      </el-form-item>
      <el-form-item label="隔离点：">
        <el-input v-model="addForm.isolation_point"></el-input>
      </el-form-item>
      <el-form-item label="起始观察期：">
        <el-date-picker
          v-model="period_observation"
          @change="getTime"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="当前温度：">
        <el-input v-model="addForm.current_temperature"></el-input>
      </el-form-item>
      <el-form-item label="最高温度：">
        <el-input v-model="addForm.maximum_temperature"></el-input>
      </el-form-item>
      <el-form-item label="在线状态：">
        <el-radio-group v-model="addForm.online_status">
          <el-radio :label="1">在线</el-radio>
          <el-radio :label="0">离线</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="医护人员：">
        <el-select
          v-model="addForm.teacher_id"
          filterable
          placeholder="请选择医护人员"
          style="width: 100%"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.teacher"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="saveForm">保存</el-button>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      area: [],
      cityArr: [],
      areaArr: [],
      showAdd: !1,
      teacherList: [],
      shipperList: [],
      period_observation: "",
      addForm: {
        name: "",
        nickname: "",
        sex: 0,
        shipper_id: "",
        province: "",
        city: "",
        county: "",
        age: "",
        mobile: "",
        birthday: "",
        address: "",
        room_num: "",
        isolation_point: "",
        period_observation: "",
        current_temperature: "",
        maximum_temperature: "",
        online_status: 1,
        teacher_id: "",
      },
    };
  },
  created() {
    let info = this.$route.query.info;
    if (info) {
      info = JSON.parse(info);
      this.handleInfo(info);
    }
    this.getArea();
    this.getTeacherList();
    this.getShipperList();
  },
  methods: {
    handleInfo(info) {
      for (let i in info) {
        for (let y in this.addForm) {
          if (y == i) {
            this.addForm[y] = info[i];
          }
        }
      }
      this.period_observation = new Date(info.period_observation * 1000);
      this.addForm.id = info.id;
    },
    // 获取省市区信息
    getArea() {
      let that = this;
      this.$axios
        .get(this.$api.area, {
          tree: 1,
          level: 2,
        })
        .then((res) => {
          if (res.code == 0) {
            that.area = res.result.list;
          }
        });
    },
    //添加隔离人员
    saveForm() {
      let url = this.addForm.id
        ? this.$api.student.edit
        : this.$api.student.add;
      this.$axios.post(url, this.addForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.addForm.id ? "编辑成功" : "添加成功");
          this.$router.push("/home/LsolationPersonnel");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTime(val) {
      if (val) this.addForm.period_observation = val.getTime() / 1000;
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find((item) => item.id == val);
        this.cityArr = index._child;
        this.addForm.city = "";
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.id == val);
        this.areaArr = index._child;
        this.addForm.county = "";
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.id == val);
      }
    },
    //获取医护人员
    getTeacherList() {
      this.$axios.post(this.$api.teacher.list, { rows: 1000 }).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.teacherList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取设备
    getShipperList() {
      this.$axios.post(this.$api.shipper.list, { rows: 10000 }).then((res) => {
        if (res.code == 0) {
          this.shipperList = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-form-item {
    width: 750px;
    text-align: left;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
